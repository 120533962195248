body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F7F7F7;
}

body.canScroll {
  overflow: initial !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

option {
  color: black !important;
}
.keyboard-key {
  background: rgba(255,255,255,.3);
  border-radius: 5px;
  display: inline-block;
  padding: 3px 5px;
  font-family: ui-monospace,SFMono-Regular,SF Mono,Menlo,Consolas,Liberation Mono,monospace;
  line-height: 10px;
  color: var(--fgColor-default,var(--color-fg-default));
  vertical-align: middle;
  border: 1px solid rgba(0,0,0,.4);
  border-radius: 6px;
  box-shadow: inset 0 -1px 0 rgba(0,0,0,.2);
}