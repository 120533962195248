p {
  color: #333333
}

ul p {
  color: #444444;
  font-size: 0.9em;
}

h1, h2, h3, h4, h5, h6 {
  /* background-image: linear-gradient(225deg, rgba(44,194,189,1) 0%, rgba(43,163,209,1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: fit-content; */
  color: #266078;
}

/* span {
  color: #333333
} */

a span {
  color: #333333
}

.Mui-error .error {
  display: block;
  color: #f44336;
}